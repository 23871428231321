#inp{
    
    padding: 15px !important;;
   width: 90%!important;
   margin-left: 30px;
   margin-bottom: 25px;
   border:0;
   /* border-bottom: 1px solid black; */
   border-bottom: 1px solid lightgrey; 

   

}
#inp:focus{
    border: 0 !important;
    box-shadow: none!important;
}
#inp::placeholder{
    color:grey;
    font-family: muliRegular;
}

._form-part{
    text-align: center;
}
input.invalid {
    border-style:none;
    border: 2px solid red;
  }

.text-area{
   
    padding: 15px;
    width: 90%;
    border:0;
    /* border-bottom: 1px solid black; */
    border-bottom: 1px solid lightgrey; 
    margin-bottom: 25px;
}
.btn-demo{
   
    border-radius: 4px;
    padding: 10px 25px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #fff;
    background-color: #446beb;
    font-family: muliRegular;
    border:none;
    
}

.btn-mob{
    display: flex;
}
@media screen and (max-width: 992px) {
#inp{
   width: 92%!important;
   margin-left: 15px!important;
   margin-right: auto!important;
   margin-bottom: 8px;
}

}

