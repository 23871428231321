*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
}
.card-link{
    cursor:pointer;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle):focus {
   box-shadow: none!important;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle):focus {
    box-shadow: none!important;
}
.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child):focus {
    box-shadow: none!important;
}

.globalcrossicon{
    width: 30px !important;
    opacity: 0.4 !important;
}
.globalcrossicon:hover{
    opacity: 1 !important;
}