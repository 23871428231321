@font-face {
    font-family: muliRegular;
    src: url(../../assets/fonts/muli/Muli-Regular.ttf);
}

@font-face {
    font-family: gothic700;
    src: url(../../assets/fonts/centurygothic/GOTHICB0.TTF);
}



@font-face {
    font-family: 'gothic';
    src: url(../../assets/fonts/SansSerifFLF-Demibold.otf)
}

@font-face {
    font-family: 'gothic-sub';
    src: url(../../assets/fonts/SansSerifFLF-Demibold.otf)
}




.glanceCard {
    width: 200px;
    max-width: 218px;
    padding: 20px;
    height: 285px;
    border-radius: 25px;


    display: flex;
    flex-direction: column;

    align-items:center;
    cursor: pointer;

}

.glancecardImg {
    margin-top: 30%;
    margin-bottom: 30%;
}

.glanceCard_nactive {
    background-color: #0a021d;
    transition: background-color 0.1s linear;
}

.glanceCard_active {
    background-color: #446beb;
    transition: background-color 0.1s linear;
    box-shadow: 0px 0px 12.2px 1.8px rgba(0, 0, 0, 0.57);


}

.glancecardtext {
    text-align:center;
    font-family: Centurygothic !important;
    color: #ffffff;
    font-size: 28px;
    font-weight:700;
    line-height: 29px;
    margin-top: -5px !important;
    text-wrap:balance;
}

@media only screen and (max-width: 992px) {
    .glanceCard {
        max-width: 110px;
        height: 150px;
    }

    .glancecardtext {
        margin-top: 14px !important;
        font-size: 14px;
    }

    .visible_mob {
        margin-top: 5px !important;
        line-height: 1.3em;
    }

    .glancecardImg {
        width: 45px;
        margin-bottom: 12px;
    }
}