.sixsvgcomb_main{
    position: relative;
    width: 450px;
    border-radius: 50%;
    min-height:350px ;

}

.sixsvgcomb_main >img{
    position: absolute;
    width: 100px;
}
.sixsvgcomb_main>img:first-child{
    left:calc(13%);
    width: 150px;
    top:3%;
}
.sixsvgcomb_main>img:nth-child(2){
    left: calc(68%);
    width: 70px;
    top:35px;
}
.sixsvgcomb_main>img:nth-child(3){
    left: 6%;
    bottom:0;
}
.sixsvgcomb_main>img:nth-child(4){
    right:8%;
    bottom:2px;
}
.sixsvgcomb_main>img:nth-child(5){
    left: -8%;
    top:20%;
}
.sixsvgcomb_main>img:nth-child(6){
    left:calc(50% - 50px);
    top:calc(50% - 60px)
}
@media only screen and (max-width: 992px) {
.sixsvgcomb_main{
        position: relative;
        max-width: 200px;
        border-radius: 0;
        min-height:450px ;
        right: 16px;
      
    }
.sixsvgcomb_main >img{
    position: absolute;
    width: 90px;
}
.sixsvgcomb_main>img:first-child{
    left:calc(13%);
    width: 110px;
    top:3%;
}
.sixsvgcomb_main>img:nth-child(2){
    left: calc(50%);
    top:70px;
    width: 60px;
}
.sixsvgcomb_main>img:nth-child(3){
    left:75px;
    bottom:90px;
    width: 60px;
}
.sixsvgcomb_main>img:nth-child(4){
    right:10%;
    bottom:195px;
    width: 60px;
}
.sixsvgcomb_main>img:nth-child(5){
    left: 0%;
    top:27%;
    width: 60px;
}
.sixsvgcomb_main>img:nth-child(6){
    left:0%;
    top:50%;
    width: 60px;
}
}