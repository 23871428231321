

@font-face {
    font-family: 'muliregular';
    src: url(../../assets/fonts/Muli_Font/Muli-Regular.ttf);
}
@font-face {
    font-family: 'mulisemibold';
    src: url(../../assets/fonts/Muli_Font/Muli-SemiBold.ttf);
}
@font-face {
    font-family: 'mulibold';
    src: url(../../assets/fonts/Muli_Font/Muli-Bold.ttf);
}

@font-face {
    font-family: 'Centurygothic';
    src: url('../../assets/fonts/Century_Gothic_Font/GOTHICB.TTF')
}

@font-face {
	font-family: 'binx';
	src: url('../../assets/fonts/Binx.ttf')
}


.hideDesktop{
    display:none;
}
.sellsol_top{
    width: 100%;
  
    overflow:hidden;

}
.sellsol_top p span{
    color:#446beb;
}
.center{
    text-align: center;
}
.sellsol_main_head{
   
color: #446beb;
font-size: 50px;
font-weight: 700;
font-family: Centurygothic !important;
margin-bottom:30px;


}
.sellsol_main_para{
    
font-size: 20px;
font-weight: 700;
line-height: 46px;
color: #58595b;
font-family: Centurygothic !important;


}
.sellsol_main_para span{
    margin-left: 0;
}
.sellsol_main_des{
    font-family: muliregular !important;
color: #58595b;
font-size: 17px;
font-weight: 400;
line-height: 27px;
margin-top: 20px;

}
.gridd{
    display:flex
}
.iconBtn{
    margin-top: -40px;
   

}

.iconBtn div{
    margin:15px 0px;
    
}
.iconBtn div button{
    font-family: muliRegular!important;
    font-weight: bold;
    padding:15px 30px !important;
}
.iconBtn div button:hover{
    transform:translate(3px,0px);
    transition:transform 0.2s linear;
}

.iconBtn .first{
    margin-left: -20px;
    margin-top: 0px;
    margin-bottom: 27px;
}
.iconBtn .sec{
    margin-left: 40px;
    margin-bottom: 30px;
}
.iconBtn .three{
    margin-left: -20px;
    margin-bottom: 30px;
}
.iconBtn button{
   
    cursor: pointer;
    margin-left: 25px;
    padding:20px 40px;
    border-radius: 5px;
    text-decoration: none;
    color:white;

background-color: #0a021d;


}
.btnactive{
    box-shadow: 0px 0px 12.2px 1px #a7a7a7;
}
.hideMobile{
    display: block;
}
@media only screen and (max-width: 992px) {
    .hideMobile{
        display:none;
    }
    .hideDesktop{
        display:block;
    }
    .selsol_mob_main{
        display: flex;
        flex-direction: column;
    }
    .selsol_mob_main >div{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .selsol_mob_main>div>div:last-child{
        padding:50px 20px 20px 20px;
        width: 90%;
        background:white;
        box-shadow: 0px 0px 25.2px 4.8px rgba(167, 167, 167, 0.25);
        margin-bottom: 30px;
        border-radius: 15px;
        font-family: muliRegular;
        text-align: justify;
        font-size: 14px;

    }

    .selsol_mob_main>div>div:nth-child(2){
        margin-top: 10px;
        border-radius: 5px;
        width: 200px;
        text-align: center;
        padding:15px 0px;
        margin-bottom: -30px;
        z-index: 1;
        font-family: muliRegular;
    }
    .sellsol_main_head{
       
        font-size: 46px!important;
        text-align: left;
        width:92%;
        margin: 100px auto 20px auto;
        
        
    }
    .sellsol_main_para{
    
        font-size: 20px!important;
        font-weight: 700;
        line-height: 1.4em;
        color: #58595b;
        width: 90%;
        margin-left: 20px;
        margin-bottom: 40px;
        text-align: left;
        
        
        
        }
    
}


@media only screen and (max-width: 360px) {

    .sellsol_main_head{
        font-size: 40px !important;
    }
    .sellsol_main_para{
        font-size: 18px !important;
    }

}
