.navbar_menu {
  background-color: none;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: auto;
  font-size: 2rem;
  margin-right: 46px;
  background: none;
}
.opennavbarcloseicon{

  margin-left:130px;
  
}
.contact_button{
  background-color: #f5f5f5;
}


.nav-menu {
  background-color: #446beb;
  width: 340px !important;
  height: 100vh;
  display: flex;
  z-index: 10;
  justify-content: center;
  position: fixed;
  /* border-radius: 0px 40px 40px 0px; */
  top: 0;
  left: -150%;
  transition: 850ms;
  border-right: 10px solid #fff;
  overflow-x: hidden;
  z-index: 100;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

/* .nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 0px 0px 1em;
  list-style: none;
  height: 60px;
  text-align: left;
 
 
}
.nav-text:first-child{
  margin-top: 20px !important;
}

.nav-text a {
 
  text-decoration: none;
  border:1px solid red;
  color: #f5f5f5;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding:0rem 2rem;
   border-top-left-radius: 20px;
   border-bottom-left-radius: 20px;

}
.nav-text a:active a::before{
  content:'';
  position:absolute;
  top:-30px;
  right:0;
  width:30px;
  height:30px;
  background:#446beb;
  border-radius:50%;
  box-shadow:15px 15px 0 #446beb;
}
.nav-text a:active {

  color: #000;
  background-color: #ffffff !important ;
 
}

.nav-text a:active a::after{
  content:'';
  position:absolute;
  bottom:-30px;
  right:0;
  width:30px;
  height:30px;
  background:#446beb;
  border-radius:50%;
  box-shadow:15px 15px 0 #446beb;
}
/* .nav-text .active {
  color: green;
} */

/* .nav-text a:hover {
  color: #000;

  background-color:#ffffff !important;
} */

.nav-menu-items {
  width: 100%;
  padding-left: 6rem;
} 

.navbar-toggle-menu{
 
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.nav-menu-items,.nav-menu-items_blue{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  padding-left: 5px;
  padding-top:40x;
  margin-left: 10px;

}

  .nav-menu-items li ,.nav-menu-items_blue li{
    position:relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom:10px !important; 
  }
  .nav-menu-items li:nth-child(3), .nav-menu-items_blue li:nth-child(3){
    margin-top: 60px !important;
  }
  

  
  .nav-menu-items li.active{
    background: white;
  
  } 
  .nav-menu-items_blue li.active{
    background: #446beb;
  
  }   
  .nav-menu-items li.active a{
    color:#446beb;
  }
  .nav-menu-items_blue li.active a{
    color:white;
  }
  .nav-menu-items li a{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color:white;
    font-family: muliregular !important;
    font-size: 20px;
    line-height: 60px;

  }

.nav-menu-items_blue li a{
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color:#446beb;
  font-family: muliregular !important;
  font-size: 20px;
  line-height: 60px;

}
  .nav-menu-items li.active a::before{
    content:'';
    position:absolute;
    top:-30px;
    right:10px;
    width:30px;
    height:30px;
    background:#446beb;
    border-radius:50%;
    box-shadow:15px 15px 0 white;
  }
  .nav-menu-items_blue li.active a::before{
    content:'';
    position:absolute;
    top:-30px;
    right:10px;
    width:30px;
    height:30px;
    background:white;
    border-radius:50%;
    box-shadow:15px 15px 0 #446beb;
  }
  .nav-menu-items li.active a::after{
    content:'';
    position:absolute;
    bottom:-30px;
    right:10px;
    width:30px;
    height:30px;
    background:#446beb;
    border-radius:50%;
    box-shadow:15px -15px 0 white;
  }
  .nav-menu-items_blue li.active a::after{
    content:'';
    position:absolute;
    bottom:-30px;
    right:10px;
    width:30px;
    height:30px;
    background:white;
    border-radius:50%;
    box-shadow:15px -15px 0 #446beb;
  }
  .nav-menu-items li a .title,.nav-menu-items_blue li a .title{
    position: relative;
    display: block;
    padding-left: 40px;
    height:60px;
    white-space: nowrap;
  }


  @media only screen and (max-width: 992px) {

    .navbar-toggle-menu img{
      margin-left: 10px !important;
      margin-top: 20px !important;
    } 
    .opennavbarcloseicon{
  
      margin-left:135px !important;
      margin-top: 22px !important;
  
      
    }
  
    }
  @media only screen and (max-width: 360px) {

  .navbar-toggle-menu img{
    margin-left: 5px !important;
    margin-top: 15px !important;
  } 
  .opennavbarcloseicon{

    margin-left:110px !important;
    margin-top: 20px !important;

    
  }

  }
/* @media only screen and (max-width: 992px) {
  span {
    margin-left: 5px;
  }
} */