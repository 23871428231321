.sub{
    margin-left: 15px;
}
.sub-head{
    font-size: 1.8rem;
    color: #446beb;
}
.italic{
    /* font-style: italic; */
    color: #446beb;
}