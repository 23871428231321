@font-face {
    font-family: binx;
    src: url(../../assets/fonts/Binx.ttf);
}

@font-face {
    font-family: 'muliregular';
    src: url(../../assets/fonts/Muli_Font/Muli-Regular.ttf);
}

@font-face {
    font-family: 'mulisemibold';
    src: url(../../assets/fonts/Muli_Font/Muli-SemiBold.ttf);
}

@font-face {
    font-family: 'mulibold';
    src: url(../../assets/fonts/Muli_Font/Muli-Bold.ttf);
}

@font-face {
    font-family: 'Centurygothic';
    src: url('../../assets/fonts/Century_Gothic_Font/GOTHICB.TTF')
}

@font-face {
    font-family: 'binx';
    src: url('../../assets/fonts/Binx.ttf')
}


.support_showcase {
    height: 690px;
    margin-top: -100px;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url('../../assets/svg/Support/BG.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    overflow-x: hidden;
    position: relative;
    overflow-y: hidden;
}

.sprinkleLeft {
    position: absolute;
    top: 300px;
    left: -100px;
}

.subscribeblur {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.subscribeblurinner {
    width: 100%;
    height: 60vh;
}

.sprinkleRight img {
    position: absolute;
    top: 100px;
    right: -100px;
    /* overflow:hidden; */
}

.support_showcase_btn {
    color: #446beb;
    font-size: 50px;
    background: #fff;
    padding: 0 50px !important;
    outline: none;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    z-index: 3;
}

.support_showcase_text {
    width: 80%;
    margin: 150px auto 50px auto;
    text-align: center;
    color: #fff;
}

.support_showcase_text h1 {
    font-family: Centurygothic !important;
    font-size: 50px;
    margin-bottom: 0px;

}

.support_showcase_text p {
    margin: 50px auto 30px auto;
    font-family: muliregular !important;
    font-size: 17px;
    text-align: center;

}

.support_showcase_text span {
    font-size: 50px;
    font-family: binx;

}

.follow_div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 200px;
    margin-left: 80px;
    margin-top: 50px;
}

.support_showcase_btn {
    font-size: 50px;
    font-family: binx;
    margin: 0px 20px 0px 40px;
}

.support_showcase_img {
    width: 200px;
    margin-top: -20px;
    height: 110px;

    transform: rotate(-15deg);
}

.support_help {
    width: 100%;

}

.support_help_top {
    width: 85%;
    margin: 80px auto;
    text-align: center;
}

.support_help_head {
    font-size: 50px;
    font-family: Centurygothic !important;
    margin-bottom: 70px;
    color: #446beb;
    font-weight: 700;
}

.support_card_text {
    font-family: Centurygothic !important;
    font-size: 20px;
    text-align: center;
}

.support_grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    /* display: lex;
    justify-content: center;
    align-items: center;f */
    margin: 0px auto 100px auto;
}

.support_grid div img {
    margin-bottom: 15px;
}

.support_grid_two {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-columns:auto auto;
    margin: 50px auto;
    justify-content: center;
    align-items: center;

}

.support_grid_two>div {
    height: 150px;
    background: white;
    box-shadow: 0px 0px 12.2px 1.8px rgba(167, 167, 167, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 25px;
    width: 95%;
    margin-bottom: 90px;
}

.support_grid_two>div:first-child {
    margin-right: 30px;
}

.support_grid_two>div img {
    margin-right: 20px;
}

.support_grid_two h4 {
    font-family: Centurygothic !important;
    font-size: 20px;
    font-weight: bold;
}

.support_grid_two p {
    font-family: muliRegular;
    font-size: 17px;

}

.supportinputstyle {
    font-family: mulisemibold !important;
}

.supportinputstyle>.supportformcontrol::placeholder {
    color: lightgrey !important;
    ;
}

.kalahideonmob {
    display: block;
}

.kalahideonweb {
    display: none;
}

@media only screen and (max-width: 992px) {
    .support_showcase {

        height: 800px;
        /* margin-top: 10px; */
    }

    .support_row {
        margin-left: calc(1.0 * var(--bs-gutter-x)) !important;
    }

    .support_grid_two>div {
        width: 96%;
    }

    .subform_top>img {
        position: absolute;
        right: 6px;
        top: 87px;
        z-index: 10;
    }

    .support_showcase_text {
        width: 85%;
        margin: 220px auto 50px auto;
        text-align: center;
        color: #fff;
    }

    .support_grid_two h4 {
        font-size: 16px;
        font-weight: bold;
    }

    .support_grid_two p {

        font-size: 13.7px;

    }

    .support_showcase_text h1 {

        font-size: 33px;
        margin-bottom: 10px;
    }

    .sprinkleLeft {
        top: 75%;

    }

    .sprinkleLeft {
        width: 170px;
    }

    .sprinkleRight img {
        top: 190px;
        width: 170px;
    }

    .support_showcase_text span {
        font-size: 22px;
        font-family: binx;

    }

    .support_showcase_btn {
        font-size: 19px;
        font-family: binx;
        margin: 0px 20px;
        border-radius: 10px;
        padding: 0 15px !important;
    }

    .support_showcase_img {
        width: 90px;
        margin-top: -48px;
    }

    .follow_div {
        margin-top: 70px;
        margin-left: 60px;
    }

    .support_help_top {
        width: 100%;
    }

    .support_help_top .input-group {
        width: 300px !important;
    }

    .support_help_head {
        font-size: 28px;
    }

    .support_grid {
        grid-template-columns: auto auto;
        grid-row-gap: 23px;
        margin: 0px auto 73px auto;
        margin-left: 14px;
    }

    .support_grid_two {
        grid-template-columns: auto;
        margin-left: 11px;


    }

    .support_grid_two>div {

        height: 118px;

    }

    .support_grid div img {
        width: 80px;
        margin-bottom: 3px;
    }

    .suport_card_mob {
        width: 160px !important;
        height: 160px !important;
    }

    .support_grid_two>div {
        margin: 20px 0px;
    }

    .support_grid_two>div img {
        width: 83px !important;
        height: 90px !important;
    }

    .support_grid_two>div img {
        margin-right: 17px;


    }


    .support_grid_two>div:first-child>div {
        margin-right: 0px;
        /* border:1px solid red; */
        margin-top: 15px;


    }

    .support_grid_two>div:last-child>div {
        margin-right: 0px;
        /* border:1px solid red; */
        margin-top: 17px;


    }

    .support_faq_margin_remove_mob {
        margin-top: -50px !important;
        margin-bottom: -200px !important;
    }

    .subformonmob {
        top: 300px !important;
    }

    .subscribeblurinner {
        margin-top: 350px;
    }

    .supportmobheadstyle {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        width: 100%;
        background: none !important;

    }

    .kalahideonmob {
        display: none;
    }

    .kalahideonweb {
        display: block;
    }

}


@media only screen and (max-width: 360px) {
    .support_showcase_text h1 {
        font-size: 27px !important;

    }

    .kalahideonweb {
        display: block;
        margin-top: 18px;
    }

    .support_showcase {
        height: 730px !important;

    }

    .support_showcase_text p {

        font-size: 14px !important;

    }

    .support_help_head {
        font-size: 24px !important;
    }

    .suport_card_mob {
        width: 135px !important;
        height: 135px !important;
    }

    .support_grid_two>div>img {
        width: 69px !important;
        height: 72px !important;

    }

    .support_grid_two>div img {
        margin-right: 10px !important;
    }

    .support_grid div img {
        width: 60px !important;

    }

    .support_card_text {

        font-size: 16px !important;

    }
}