/* 
@font-face {
    font-family: muliRegular;
    src: url(../../assets/fonts/muli/Muli-Regular.ttf);
}
@font-face {
    font-family: gothic700;
    src: url(../../assets/fonts/centurygothic/GOTHICB0.TTF);
}

@font-face {
	font-family: 'gothic';
	src: url('../../assets/fonts/SansSerifFLF-Demibold.otf')
}
@font-face {
	font-family: 'gothic-sub';
	src: url('../../assets/fonts/SansSerifFLF-Demibold.otf')
} */

@font-face {
    font-family: 'muliregular';
    src: url(../../assets/fonts/Muli_Font/Muli-Regular.ttf);
}
@font-face {
    font-family: 'mulisemibold';
    src: url(../../assets/fonts/Muli_Font/Muli-SemiBold.ttf);
}
@font-face {
    font-family: 'mulibold';
    src: url(../../assets/fonts/Muli_Font/Muli-Bold.ttf);
}

@font-face {
    font-family: 'Centurygothic';
    src: url('../../assets/fonts/Century_Gothic_Font/GOTHICB.TTF')
}
.bg-gradients{
    width: 100%;
min-height: 385px;
position: relative;
/* background: url('../../assets/BG.png'); */


background: rgb(10,2,29);
background: linear-gradient(180deg, rgba(10,2,29,1) 41%, rgba(27,19,47,1) 91%);




}
.dropline{
    position: absolute;
    width: 370px;
    height: 410px;
  
}
.drop{
    background:white;
    padding:15px 25px;
    border-radius: 5px;
    width: fit-content;
}

.drop p{
    font-family: Binx;
color: #3c4658;
font-size: 23px;
font-weight: 400;
margin:0;

}
.dropdiv1{
    position: absolute;
    top:110px;
    right: 70px;
}
.dropdiv2{
    position: absolute;
    top:240px;
    right: 0px;
}
.left_child{
    height: 385px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.left_child_head{
font-family: Centurygothic;
color: #ffffff;
font-size: 47px;
font-weight: 600;

}
.left_child_subhead{
    /* font-family: 'Mulish', sans-serif; */
    font-family:mulisemibold ;
color: #ffffff;
font-size: 17px;
font-weight: 400;
width: 70%;

margin:20px 0px 65px 0px;

}
.left_child_para{
    /* font-family: 'Mulish', sans-serif; */
    font-family: mulisemibold;
font-size: 17px;
font-weight: 400;
color: #ffffff;

}
.left_child_para a{
    text-decoration: none;
    color:#446beb;
    font-family: mulisemibold!important;
    font-weight: 700 !important;
    font-size: 17px!important;
}
@media only screen and (max-width: 992px) {
    .bg-gradients{
        overflow:hidden;
        height: 641px;
    }
    .cont_mob{
        width: 95%;
        margin: 0 auto;
    }
    .drop{
        padding:7px 5px;
        width: 190px;
    }
    .drop p{
       text-align: center;
    font-size: 17px;
    
    
    }
    .dropline {
        width: 228px !important;
        left: 49px !important;
        top: -68px !important;
    }
    .dropdiv1 {
        top: 72px !important;
        right: 74px !important;
    }
    .dropdiv2 {
        top: 167px !important;
        right: -3px !important;
    }
    .left_child{
        margin-bottom: -40px;
    }
    .left_child_head{
        margin-top: -10px;
        font-size: 40px;
    }
    .left_child_para{
        font-size: 15px;
    }
    .left_child_subhead{
          font-size: 15px;
    
    width: 100%;
    word-spacing: 0.1em;
    margin:20px 0px 25px 0px;
    
    }
}

@media only screen and (max-width: 360px) {
    .dropline {
        width: 228px !important;
        left: 49px !important;
        top: -68px !important;
    }
    .dropdiv1 {
        top: 72px !important;
        right: 29px !important;
    }
    .dropdiv2 {
        top: 170px !important;
        right: -35px !important;
    }

}