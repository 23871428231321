
  @font-face {
    font-family: 'muliregular';
    src: url(../../assets/fonts/Muli_Font/Muli-Regular.ttf);
}
@font-face {
    font-family: 'mulisemibold';
    src: url(../../assets/fonts/Muli_Font/Muli-SemiBold.ttf);
}
@font-face {
    font-family: 'mulibold';
    src: url(../../assets/fonts/Muli_Font/Muli-Bold.ttf);
}

@font-face {
    font-family: 'Centurygothic';
    src: url('../../assets/fonts/Century_Gothic_Font/GOTHICB.TTF')
}


.main_blog_heaed{
  font-family: Centurygothic !important;
  font-size: 50px;
  color:#446beb;
  font-weight: bold;
  margin-top: 40px;
}
#blog_hd{
    text-align: center;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */

#med_inp{
    display:flex;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    padding-right:40px;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 40px;
    padding-top:5px;
    padding-bottom: 5px;
  
  }

  .search_inp{
      padding-left: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
     
  }
  .search_inp img{
    transform: rotate(-90deg);
    width: 28px;
    margin-right: 10px;
  }
  .blog_search{
    font-size: 18px;
    color:#446beb;
  }
  #search-id{
    font-family: mulisemibold !important;
    font-size: 25px;
    color:c5c5c5 !important
  }
  /* .inputfoucsremove{
    border:1px solid red !important;
  } */
  .inputfoucsremove:focus{
    border:0px !important;
    box-shadow: none !important;
    outline: 0 !important;
  }
  #search-id:focus{
    border:none;
    box-shadow: none;
  }
 
  #search-id{
    box-shadow: none;
    border:0px;
 
  }

  .dropdown #dropdown{
      background: none;
     
  }
  #dropdownMenuButton{
 color:#6b7c93;
      font-size: 25px;
      font-family: muliRegular;
  }
  .blog_p{
    width: 80%;
    margin:30px auto 0px auto;
    text-align: center;
    color:black;
    font-size: 17px;
    font-family: muliregular !important;
    
  }
  .tester
  {
    width: 100%;
    overflow:scroll;
  }
  .tester::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .tester {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
    
  .blogthreecards{
    display: flex;
      margin-top: 50px;
      padding-bottom: 50px;
      border-bottom: 0;
  }
  .blogthreecards > *:not(:last-child){
    margin-right: 30px;
  }
  .blog_section_3{
      margin-top: 10px;
      margin-bottom: 50px;
      min-height: 300px;
    
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #446beb !important;
    color:white;
}
  .blog_nav ul{
    text-decoration: none;
    list-style: none;
    display: flex;
    width: 40%;
    justify-content: space-between;
    margin-bottom: 40px;

  }
  .blog_nav ul li:first-child{
 
    margin-left:10px !important;

  }
  .blog_nav ul li a{
        text-decoration: none;
        font-size: 25px;
        font-family: mulisemibold !important;
        font-weight: bold;
        color:#6b7c93;
  }
  .blog_nav_active{
    color: #446beb !important;
    padding-bottom:10px;
    border-bottom:1px solid #446beb;
  }
 

  .blog-left-1{
      float: left;
      width: 50%;
      margin-bottom: 10px;
     
      
  }
  .blog-left-1 p{
    margin-top: 15px;
    font-size: 18px !important;
    font-family: mulibold !important;
    font-weight: bold;
    color:black;
  }
  .blog-right{
      float:right;
      width: 50%;
      padding-left: 50px;
     
  }
  .blog-right img{
      width: 100% !important;
    
  }
  .blog-left-2{
      float:left;
      width: 45%;
  }
  .blog-left-2 p{
    font-size: 17px;
    font-family: muliregular !important;
    color:black;
  }
  .avatar_div{
      display: flex;
      align-items: center;
      padding-left: 5px;
      margin-bottom: 10px;
  }
  
  #hd{
    margin-left: 17px;
    font-size: 17px;
    font-family: mulibold !important;
    font-weight: bold;
    color:black;
    
   
  }
  .div_hd {
    
    border-left: 4px solid #446beb;
    /* height: 50px; */
    margin-bottom: 70px;
    margin-left: 2px;
    padding-left: 20px;
    margin-top: 150px;
  }

  .div_hd_inner p:first-child{
      font-size: 18px;
      font-family: mulibold !important;
      font-weight: bold;
      color:black;
  }
  .div_hd_inner p:last-child{
    font-size: 17px;
    font-family: muliregular !important;
    color:black;
  }
  .div_hd h1{
    font-size: 54px;
    font-family: Centurygothic !important;
    font-weight: bold;
    color:#3c4658;
    text-transform: capitalize;
    /* letter-spacing: 0.02em; */
    /* boxShadow:'4px 4px 21px 3px rgba(138,138,138,0.5)!important; */
  }
  
  
  #hd2{
    margin-left: 20px;
    margin-top: 20px;
   
  }
  .p_inp{
    margin-top: 110px;
    display:flex;
   
    background-color: #e0ecfc;
    align-items: center;
    min-height:170px ;
  }
  
 
  #email_inp{
    margin-top: 20px;
    border-radius: 35px;
    width:310px;
    padding:20px 20px;
    border:1px solid lightgray;
  
   
    
  }
  #email_inp::placeholder{
      color:darkgray;
  }
  #email_inp:focus{
      border:1px solid darkgray !important;
      box-shadow: none !important;
  }
  #med_par{
      margin-top: 15px;
    width: 100%;
    font-size: 18px;
    font-family: mulibold !important;
    font-weight: bold;
    color:#446beb;
    line-height: 2em;
  }
  
  .dropdown-toggle::after{
    border:0 !important;
  }
  .dropdown-toggle:focus{
    border:none !important;
    box-shadow: none !important;
  }
  .dropdown-toggle span {
    font-family: mulisemibold !important;
    font-size: 25px !important;
    color:#6b7c93 !important;
  }
  .parthideonmob{
    display:block;
  }
  .partshowonmob{
    display:none
  }
  #submit_btn{
    border-radius: 35px;
    width:120px;
        margin-left: 20px;
        padding:20px 10px;
        width: 150px;
        color:white;
        background:#446beb;
        border:none;
        font-size: 17px;
        font-family: muliRegular;
    
  }
  #submit_btn:hover{
    background:#5f7fe6
  }
  .blog_section_6{
    margin: 60px 0px 0px 0px;
  }
  .blog_section_6 div h2{
    font-size: 35px;
    font-family: muliregular;
    font-weight: bold;
    color:#3c4658;
  }
  .section_6_separator{
        min-height: 400px;
        font-size: 17px;
        font-family: muliregular !important;
  }
  .section_6_left{
      width: 50%;
      float:left;
      margin-right: 80px !important;
  }
  .section_6_left>div{
    box-shadow:4px 4px 12px 1px rgba(138,138,138,0.2)!important; 
  }
  .section_6_right{
      width: 45%;
      float:right;
      margin-left: 30px;
  }
  .section_6_right p{
    font-size: 17px;
    font-family: muliregular;
    font-weight: 400;
    color:black;
  }
  .blog_section_6 .avatar_div{
    margin-top:20px 0px;
  }
  .bbadge{
    border-radius: 2px;
    border: 1px solid #e0ecfc;
    padding: 1px 9px;
    margin-left: 8px;
    margin-top: -7px;
    color: #6b7c93;

  }
   .inp_btn{
     margin-left:60px;
   }
   .supp_hd h2{
    font-family: mulibold !important;
    font-size: 35px !important;
    margin-bottom: 15px;
   }
  @media (max-width: 992px) {
    .div_hd {
    
      border-left: 4px solid #446beb;
      /* height: 50px; */
      margin-bottom: 30px;
      margin-left: 11px;
      padding-left: 20px;
      margin-top: 150px;
    }
    .main_blog_heaed{
      font-size: 35.82px;

    }
    .blogthreecards
    {
      width: 200%;
    }
    .blog_p{
      width: 100%;
      font-size: 15px;
     
    }
    #med_inp{
      padding-top: 2;
      padding-bottom: 2;
      padding-right:0;
    }
    .dropdown-toggle> span{
    
      font-size: 15px !important;
      font-family: mulisemibold !important;
    }
    .dropdown-toggle img{
      width: 15px !important;
      margin-left: 10px !important;
    }
   
    .search_inp{
    
      padding-left: 10px;

    }
    .search_inp img{
      width: 19px !important;
      margin-right:0 !important ;
    }
    #search-id{
      font-size: 15px;
      font-family: muli;
    }
   
    #search-id::placeholder{
      font-size: 15px;
      font-family: muliRegular;
    }
    #dropdownMenuButton{
      font-size: 15px;
    }
    .mobwidth{
      width: 95% !important;
      margin:0 auto;
    }
    .mobwidth:first-child{
      margin-bottom: 40px;
    }
     .blog_nav ul{
     
       width: 90%;
       margin: 0 auto;
       padding-left: 0;

     }
     .blog_nav ul li a{
       font-size: 20px;
     }
     .blog-left-1{
       float:none;
       width: 100%;
       margin-top:35px;
     }
     .blog-left-1 p{
       font-size: 12px !important;
       line-height: 1.6em;
     }
     .blog-right{
       float:none;
       width: 100%;
       padding-left: 0;
       margin-top: 30px;
     }
     .blog-left-2{
       float: none;
       width: 100%;
       margin-top:30px;
       padding-left: 13px;
     }
     .blog-left-2 p{
       font-size: 14px;
     }
     #hd{
       font-size: 15px;
     }
     .div_hd{
       margin-top: 80px;
     }
     .div_hd h1{
      font-family: Centurygothic !important;
       font-size: 34px;
     }
     .overflowimg img{
       width: 100%;
     }
     .marginremove{
       margin-top: 20px !important;
     }
     .div_hd_inner p:first-child{
       font-size: 18px;
       margin-top: 20px;
       color:black;
     
     }
     .div_hd_inner p:last-child{
      font-size: 14px;
      color:black;
      text-align: justify;
    
    }
    .section_6_left{
      width: 70%;
      margin-right: 9px !important;
    }
    .section_6_left img{
      width: 100% !important;
    }
    #med_par{
      margin-top: 30px;
      width: 100%;
      text-align: center;
      font-size: 15px;
    }
    .inp_btn{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      margin-left: -10px;
    }
    #email_inp {
      margin-top: 0;
      width: 180px;
      padding: 8px 15px;
      padding-top: 6px;
  }
    #submit_btn{
      padding:7px 15px;
      width:100px;
    }
    .supp_hd h2 {
      font-size: 30px!important;
      margin-bottom: 20px;
     
      
    }
    .blog_section_6 .avatar_div{
      margin-bottom: 20px;
    }
    .section_6_separator{
      width: 100%;
      height: 420px !important;
      font-size: 15px;
      padding:2px 8px;
     
     

    }
    .blog_section_6{
     
      margin-bottom: 50px;

    }
    .section_6_separator img{
      width: 98%;
      padding-right:0px;
    }
    
    .section_6_right p{
      font-size:15px;

    }
    .mobmargin{
      margin-top: 170px;
    }
    .p_inp{
      margin-top: 60px !important;
    }
    .paginationmob{

      margin-top: 80px;
      margin-bottom: 240px !important;
      z-index: 100 !important;
    }
    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 1) !important;
      padding-left: calc(var(--bs-gutter-x) * .8) !important;
      margin-top: var(--bs-gutter-y);
  }
  .section_6_left{
   
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .section_6_left >div{
    width: 96% !important;
  }
  .parthideonmob{
    display:none;
  }
  .partshowonmob{
    display:block
  }
   }
  
  
   
  @media only screen and (max-width: 360px) {

    .div_hd h1 {
      font-size: 30px !important;
   
  }
  #med_par {
    
    font-size: 13px !important;
}

#email_inp {
 
  font-size: 14px !important;
}
#submit_btn {

  font-size: 14px !important;

}
.section_6_separator {
 
  font-size: 14px !important;
 
}
.section_6_left {
  width: 58% !important;
}
.section_6_separator img{
  width: 100% !important;
}
  
  }