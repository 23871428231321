@font-face {
	font-family: 'gothic';
	src: url('./SansSerifExbFLF.otf');

}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
.fontsforweb_fontid_9785 {
	font-family: 'gothic' !important;
}

/* Font downloaded from FontsForWeb.com */