
.heading_3{
    font-family: "gothic";
    font-size: 38px;
    color: #446beb;
  
    margin-top: 50px !important;
    font-weight: bold;
    line-height: 1.1;
}
.signuplist{
    display: flex;
    justify-content: space-between;
}
.signuplist li{
    font-family: muliRegular;
    font-size: 18px;
    list-style: none;
    margin: 5px 0;
    margin-left: -30px;
    padding: 8px 0 4px 30px;
    background: url(images/bullet-style0.png) no-repeat left center;
  
}
.signuplist li:first-child{
    margin-top: 20px;
}
.signuplist li{
    display: list-item;
    margin-bottom: 20px;
}

@media only screen and (max-width: 992px) {
    .signuplist li{
        font-family: muliRegular;
        font-size: 14px;
        list-style: none;
        margin: 5px 0;
      
        margin-left: -30px;
        padding: 8px 0 4px 30px;
        background: url(images/bullet-style0.png) no-repeat left center;
      
    }

}
