@font-face {
  font-family: muliRegular;
  src: url(../../assets/fonts/muli/Muli-Regular.ttf);
}

@font-face {
  font-family: gothic700;
  src: url(../../assets/fonts/centurygothic/GOTHICB0.TTF);
}

@font-face {
  font-family: 'gothic';
  src: url('../../assets/fonts/SansSerifFLF-Demibold.otf')
}

@font-face {
  font-family: 'gothic-sub';
  src: url('../../assets/fonts/SansSerifFLF-Demibold.otf')
}
.signUp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-h {
  margin-top: 90px !important;
  /* text-align: center; */
  font-family: Centurygothic !important;
  color: #446beb;
  margin-bottom: 25px !important;
  /* padding: 0 !important; */
  font-weight: bold;
  font-size: 62px;

}

.home-s {
  margin-top: 50px !important;
  text-align: center;
  font-family: Centurygothic !important;
  color: #446beb;
  margin-bottom: 25px !important;
  padding: 0 !important;
  font-weight: bold;

  font-size: 52px;

}

.margin-top {
  margin-top: 10px !important;
}

.home-p {
  min-height: 1px;
  padding-right: 25px;
  font-family: muliregular !important;
  color: #4d4d4f;
  font-size: 20px;
}



@media screen and (max-width: 992px) {

  .home-h {
    margin-top: 90px !important;
    text-align: center;
    font-family: Centurygothic !important;
    color: #446beb;
    margin-bottom: 5px !important;
    padding: 0 !important;
    font-weight: bold;

    font-size: 34px !important;

  }

  /* .signupmobleft{
      margin-left: 10px !important;
    } */
  .signupmobleft {
    margin-left: 8px !important;
  }

}


@media only screen and (max-width: 360px) {
  .home-h {

    font-size: 30px !important;
  }

  .home-p {

    font-size: 17px !important;
  }

  .heading_3 {

    font-size: 34px !important;


  }

  .signuplist li {
    font-size: 12px !important;
  }

  .c-form {


    padding: 10px 0px 15px 0px !important;
    min-height: 600px !important;

  }

  .home-s {

    font-size: 36px !important;
  }
}